import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import "./layout.styles.scss";
export default class RightMenu extends Component {
  render() {
    return (
      <Menu style={{ lineHeight: "59px" }} mode="horizontal">
        <Menu.Item key="5">
          <a href="https://app.agylz.net/login">Login</a>
        </Menu.Item>

        <Menu.Item key="6">
          <a href="https://app.agylz.net/register">Register</a>
        </Menu.Item>
      </Menu>
    );
  }
}

import React from "react";
import "./getReady.styles.scss";
import button from "../../brandedButton/index";
import { Header, Box } from "../../../styles/theme";
import { Button } from "../../brandedButton/style";
import { Link } from "react-router-dom";
class ForSchoolGetReady extends React.Component {
  render() {
    return (
      <div className="inspireHeader">
        <Box>
          <Header>Apply for our {new Date().getFullYear()} free program</Header>
          <p>
            Education that empowers students to solve problems and move product
            innovation forward
          </p>
        </Box>
        <Link to="/programform">
          <Button>Apply Now</Button>
        </Link>
      </div>
    );
  }
}

export default ForSchoolGetReady;

import styled from "styled-components";

export default {
  brand: {
    blue: "#005075",
    button: "#138FD2",
    grey: "#aeb3c0",
    darkGrey: "#515151",
    pink: "#fd687d",
    yellow: " #fbab18",
    green: "#6ebf49",
    move: " #403152",
    greyBackground: "#F8F8F8",
  },
  disabled: "#ccc",
  containerWidth: "1260px",
};

export const Header = styled.h1`
  color: ${({ theme }) => theme.brand.blue};
  font-size: 40px;
  text-align: center;
  padding: 33px 0px;
  
  font-weight: bold;
  @media (min-width: 320px) and (max-width: 600px) {
    font-size: 20px !important;
`;
export const Box = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0px auto;
  max-width: ${({ theme }) => theme.containerWidth};
  align-content: center;

  @media (max-width: 1260px) {
    padding: 0px 20px;
  }
`;

import React, { Component } from "react";
import footerlogo from "../../Images/footer-logo.svg";

import "./layout.styles.scss";
import { Link } from "react-router-dom";
var FA = require("react-fontawesome");
export default class FooterList extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col col-12 col-sm-6 col-md-4 centerImg">
            <div className="centerImg">
              <img
                src={footerlogo}
                className="footerlogo"
                alt="Agylz"
                width={60}
              />
            </div>
            <h1 style={{ fontSize: 20, marginTop: 10, fontWeight: 800 }}>
              AGYLZ
            </h1>
          </div>

          <div className="col col-12 col-sm-6 col-md-4">
            <div className="row" style={{ marginTop: 30 }}>
              <div className="col col-6 col-sm-6 col-md-6">
                <ul style={{}}>
                  <li>
                    <Link to="/" className="whiteTxt ml37">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/product" className="whiteTxt ml37">
                      Product
                    </Link>
                  </li>
                  <li>
                    <Link to="/pricing" className="whiteTxt ml37">
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link className="whiteTxt ml37" to="/privacy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="whiteTxt ml37" to="/terms">
                      Terms
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col col-6 col-sm-6 col-md-6">
                <ul style={{}}>
                  <li>
                    <Link to="/eula" className="whiteTxt ml37">
                      EULA
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="whiteTxt ml37">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="whiteTxt ml37">
                      Support
                    </Link>
                  </li>

                  <li>
                    <a
                      style={{ textDecoration: "none", color: "white" }}
                      href="https://app.agylz.net/register"
                    >
                      <span>Register</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col col-12 col-sm-6 col-md-4">
            <h4
              style={{ textAlign: "center", fontWeight: 200, fontSize: 20 }}
              className="mobileMT20"
            >
              Follow us on
            </h4>
            <ul
              className="list-inline text-center align-items-center"
              style={{ marginTop: 10 }}
            >
              <li className="list-inline-item">
                <a
                  rel=" noreferrer"
                  href="https://www.facebook.com/agylz/?ref=bookmarks"
                  target="_blank"
                >
                  <FA
                    name="facebook-square"
                    style={{
                      color: "white",
                      fontSize: 30,
                    }}
                  />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  rel=" noreferrer"
                  href="https://www.linkedin.com/company/20474226/admin/"
                  target="_blank"
                >
                  <FA
                    name="linkedin"
                    style={{
                      color: "white",
                      fontSize: 30,
                    }}
                  />
                  {/* <img width={30} src={twitter} alt="" /> */}
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  rel="noreferrer"
                  href="https://twitter.com/AgylzLlc"
                  target="_blank"
                >
                  <FA
                    name="twitter-square"
                    style={{
                      color: "white",
                      fontSize: 30,
                    }}
                  />
                </a>
              </li>
            </ul>

            <h4
              style={{ textAlign: "center", fontWeight: 200, fontSize: 20 }}
              className="mobileMT20"
            >
              Integrations
            </h4>
            <ul
              className="text-center align-items-center"
              style={{ marginTop: 10 }}
            >
              <li className="item">
                <Link to="/zoom" className="whiteTxt ml37">
                  Zoom
                </Link>
              </li>
              {/* <li className="item">
                <Link to="" className="whiteTxt ml37">
                  Github
                </Link>
              </li> */}
            </ul>
            {/* <p className="down" style={{ marginTop: 20 }}>
              DOWNLOAD IT NOW
            </p>

            <ul class="list-inline text-center align-items-center">
              <li class="list-inline-item">
                <img
                  src={app}
                  alt="appstore"
                  className="googleplay"
                  style={{ width: 70 }}
                />
              </li>
              <li class="list-inline-item">
                <img
                  src={play}
                  alt="googleplay"
                  className="googleplay"
                  style={{ width: 70 }}
                />
              </li>
            </ul> */}
          </div>
        </div>
        <div className="center">
          <p className="copy">
            © Copyright {new Date().getFullYear()} Agylz LLC, All rights reserved.
          </p>
        </div>
      </div>
      // <div>
      //   <Row type="flex" justify="center">
      //     <Col lg={8} md={4} sm={24} xs={24}>
      //       <div className="centerImg">
      //         <img
      //           src={footerlogo}
      //           className="footerlogo"
      //           alt="Agylz"
      //           width={60}
      //           style={{ marginTop: 20 }}
      //         />
      //       </div>
      //     </Col>

      //     <Col span={3} lg={3} md={4} sm={8} xs={12} className="mediaTop">
      //       <ul style={{ marginTop: 20 }}>
      //         <li>
      //           {/* <i className="fa fa-facebook"></i> */}
      //           <Link to="/" className="whiteTxt">
      //             Home
      //           </Link>
      //         </li>
      //         <li>
      //           <Link to="/product" className="whiteTxt">
      //             Product
      //           </Link>
      //         </li>
      //         <li>
      //           <Link to="/pricing" className="whiteTxt">
      //             Pricing
      //           </Link>
      //         </li>
      //         <li>
      //           <Link className="whiteTxt" to="/privacy">
      //             Privacy Policy
      //           </Link>
      //         </li>
      //         <li>
      //           <Link className="whiteTxt" to="/terms">
      //             Terms & Conditions
      //           </Link>
      //         </li>
      //       </ul>
      //     </Col>
      //     <Col span={3} lg={3} md={4} sm={8} xs={12} className="mediaTop">
      //       <ul style={{}}>
      //         <li>
      //           <Link to="/eula" className="whiteTxt">
      //             EULA
      //           </Link>
      //         </li>
      //         <li>
      //           <Link to="contact" className="whiteTxt">
      //             Contact Us
      //           </Link>
      //         </li>
      //         <li>
      //           <Link to="contact" className="whiteTxt">
      //             Support
      //           </Link>
      //         </li>
      //         <li>
      //           <Link className="whiteTxt">Register</Link>
      //         </li>
      //       </ul>
      //     </Col>

      //     <Col lg={8} md={12} sm={9} xs={20} className="mediaTop">
      //       <h4 style={{ textAlign: "center", fontWeight: 200, fontSize: 20 }}>
      //         Follow us on
      //       </h4>
      //       <Row
      //         type="flex"
      //         justify="center"
      //         className="customW"
      //         style={{ marginTop: 30 }}
      //       >
      //         <Col lg={4} md={4} sm={1} xs={3} className="centerImg">
      //           <a
      //             href="https://www.facebook.com/agylz/?ref=bookmarks"
      //             target="_blank"
      //           >
      //             <FA
      //               name="facebook-square"
      //               style={{
      //                 color: "white",
      //                 fontSize: 30
      //               }}
      //             />
      //             {/* <img src={face} width={30} alt="" /> */}
      //           </a>
      //         </Col>
      //         <Col lg={4} md={4} sm={1} xs={3} className="centerImg">
      //           <a
      //             href="https://www.linkedin.com/company/20474226/admin/"
      //             target="_blank"
      //           >
      //             <FA
      //               name="linkedin"
      //               style={{
      //                 color: "white",
      //                 fontSize: 30
      //               }}
      //             />
      //             {/* <img width={30} src={twitter} alt="" /> */}
      //           </a>
      //         </Col>
      //         <Col lg={4} md={4} sm={1} xs={3} className="centerImg">
      //           <a href="https://twitter.com/AgylzLlc" target="_blank">
      //             {/* <img width={30} src={instagram} alt="" /> */}
      //             <FA
      //               name="twitter-square"
      //               style={{
      //                 color: "white",
      //                 fontSize: 30
      //               }}
      //             />
      //           </a>
      //         </Col>
      //       </Row>
      //       <p className="down" style={{ marginTop: 50 }}>
      //         DOWNLOAD IT NOW
      //       </p>
      //       <Row type="flex" justify="center">
      //         <Col
      //           lg={8}
      //           md={11}
      //           sm={11}
      //           xs={11}
      //           style={{ textAlign: "right" }}
      //         >
      //           <img
      //             src={app}
      //             alt="appstore"
      //             className="googleplay"
      //             style={{ width: 90 }}
      //           />
      //         </Col>
      //         <Col span={1} />
      //         <Col lg={8} md={11} sm={11} xs={11}>
      //           <img
      //             src={play}
      //             alt="googleplay"
      //             className="googleplay"
      //             style={{ width: 90 }}
      //           />
      //         </Col>
      //       </Row>
      //     </Col>
      //   </Row>
      //   <Row type="flex" justify="center">
      //     <Col span={5} xs={24} sm={24}>
      //       <p className="copy">
      //         © Copyright 2020 Agylz llc, All rights reserved.
      //       </p>
      //     </Col>
      //   </Row>
      // </div>
    );
  }
}

import React, { Component } from "react";
import { Tabs } from "antd";
import Monthly from "./Monthly";
import Annually from "./Annually";
import "./style.css";

const { TabPane } = Tabs;

function callback(key) {}

export default class PackagesCard extends Component {
  render() {
    return (
      <div>
        {" "}
        <Monthly />
        {/* <Tabs type="card" defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Monthly" key="1">
            <Monthly />
          </TabPane>
          <TabPane tab="Annually" key="2">
            <Annually />
          </TabPane>
        </Tabs>{" "} */}
      </div>
    );
  }
}

import React from "react";
import "./style.css";
import { Row, Col, List } from "antd";
import img_mobile from "../../../Images/img_mobile_screen.png";
import img_android from "../../../Images/img_google_play.png";
import img_appStore from "../../../Images/img_app_store.png";
import ic_disc from "../../../Images/ic_disc.png";
import imgApp from "../../../Images/img_mobile_screen.png";
import { Header, Box, BoxSmall } from "../../../styles/theme";

const listItems = [
  {
    title: "Product innovation management.",
  },
  {
    title: "Idea management.",
  },
  {
    title: "Interactive workshop sessions.",
  },
  {
    title: "Intuitive roadmapping.",
  },
  {
    title: "Digital white board.",
  },
  {
    title: "Chat rooms to text your team.",
  },

  {
    title: "Meeting agenda organizer.",
  },
  {
    title: "Agile",
  },
  // {
  //   title: "Prototype and Wireframing tool.",
  // },
  {
    title: "Surveys and live voting.",
  },
  {
    title: "Presentation builder.",
  },
  {
    title: "Proof of concept management.",
  },
  {
    title: "Reminders and notifications.",
  },
  // {
  //   title: "Collecting requirements",
  // },
];
class Mobileapp extends React.Component {
  render() {
    return (
      <section className="dadSection">
        <Box>
          <Box>
            <div>
              <Header className="headerTitle">Agylz Mobile App</Header>
              <p className="headerStyle" style={{ color: "black" }}>
                One app unifies all the tools you need in your phone. No more
                swapping between different apps, it’s all available at your
                fingertips.
              </p>
            </div>
            <div className="center mobile">
              <img src={imgApp} className="imgStyle" />
            </div>
          </Box>
          <div className="spacing-div"></div>

          <section>
            <div className="discoverBody">
              <Row>
                <Col lg={10} md={12} sm={24}>
                  <List
                    dataSource={listItems}
                    renderItem={(item) => (
                      <List.Item
                        style={{ borderBottom: "none", marginBottom: 0 }}
                      >
                        <List.Item.Meta
                          avatar={<img src={ic_disc} />}
                          title={<p className="listTitle">{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                </Col>
                <Col lg={6} md={12} sm={24} className="web">
                  <Row>
                    <Col offset={4}>
                      <img src={imgApp} className="imgStyle" />
                    </Col>
                  </Row>

                  {/* <Row className="download">
                    <div className="centerAligned">
                      <p className="inspiration" style={{ fontSize: 26 }}>
                        DOWNLOAD IT NOW
                      </p>
                      <Row className="downloadLinks">
                        <Col span={11} style={{}} className="floatRight">
                          <img
                            src={img_android}
                            className="imageDownload floatRight"
                          />
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11} style={{}} className="floatLeft">
                          <img
                            src={img_appStore}
                            className="imageDownload floatLeft"
                          />
                        </Col>
                      </Row>
                    </div>
                  </Row> */}
                </Col>
              </Row>
            </div>
          </section>
        </Box>
      </section>
    );
  }
}

export default Mobileapp;

import React, { Component } from "react";
import "./Massage.styles.scss";
import { Input, Row, Col } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Link } from "react-router-dom";
import axios from "axios";
export default class Massage extends Component {
  state = {
    firstname: "",
    lastname: "",
    email: "",
    company: "",
    job: "",
    phone: "",
    msg: "",
    isError: false,
    error: "",
    success:
      "We received your request and one of our specialists will contact you!",
    isSuccessVisable: false,
  };
  sendEmail() {
    this.setState({
      visible: false,
    });
    let subject = "Contact Us ";
    let txt = `First Name : ${this.state.firstname} \n Last Name : ${this.state.lastname} \n Email : 
    ${this.state.email} \n Mobile Number : ${this.state.phone} \n Company : ${this.state.company} \n Job Title: ${this.state.job} \n Message: ${this.state.msg}`;
    const data = {
      subject: subject,
      text: txt,
      to: "agylzllc@gmail.com",
      name: `${this.state.firstname} ${this.state.lastname}`,
      from: this.state.email,
    };
    axios
      .post("https://service.agylz.net/api/contact", data)
      .then((response) => {
        this.setState({
          isError: false,
          isSuccessVisable: true,
          error: "",
          firstname: "",
          lastname: "",
          email: "",
          company: "",
          job: "",
          phone: "",
          msg: "",
          success:
            "We received your request and one of our specialists will contact you!",
        });
        setTimeout(() => {
          this.setState({ isSuccessVisable: false });
        }, 7000);
      })
      .catch((e) => {
        this.setState({
          isError: false,
          error: "",
          firstname: "",
          lastname: "",
          email: "",
          company: "",
          job: "",
          phone: "",
          msg: "",
        });
      });
  }
  showModal = () => {
    if (this.state.firstname !== "") {
      if (this.state.lastname !== "") {
        if (this.state.email !== "") {
          if (this.state.job !== "") {
            if (this.state.company !== "") {
              if (this.state.msg !== "") {
                this.sendEmail();
              } else {
                this.setState({
                  isError: true,
                  error: "Please Enter Your Message",
                });
              }
            } else {
              this.setState({
                isError: true,
                error: "Please Enter Your Company Name",
              });
            }
          } else {
            this.setState({
              isError: true,
              error: "Please Enter Your Job Title",
            });
          }
        } else {
          this.setState({
            isError: true,
            error: "Please Enter A Vaild Email",
          });
        }
      } else {
        this.setState({
          isError: true,
          error: "Please Enter Your Last Name",
        });
      }
    } else {
      this.setState({
        isError: true,
        error: "Please Enter Your First Name",
      });
    }
  };
  render() {
    return (
      <div className="fullWidth">
        {this.state.isError && (
          <div className="error" style={{ marginTop: 10, marginBottom: 10 }}>
            {this.state.error}
          </div>
        )}

        {this.state.isSuccessVisable && (
          <div className="success" style={{ marginTop: 10, marginBottom: 10 }}>
            {this.state.success}
          </div>
        )}
        <Row gutter={4} className="inputLine">
          {/* <div className="inputLine"> */}
          <Col lg={12} md={12} sm={24} xs={24} style={{ marginBottom: "10px" }}>
            <Input
              className="singleInputR"
              size="large"
              value={this.state.firstname}
              placeholder="First Name"
              onChange={(e) => {
                this.setState({
                  firstname: e.target.value,
                  isError: false,
                });
              }}
            />
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Input
              className="singleInputL"
              size="large"
              value={this.state.lastname}
              placeholder="Last Name"
              onChange={(e) => {
                this.setState({
                  lastname: e.target.value,
                  isError: false,
                });
              }}
            />
          </Col>
          {/* </div> */}
        </Row>
        <Row gutter={4} className="inputLine">
          <Col lg={12} md={12} sm={24} xs={24} style={{ marginBottom: "10px" }}>
            <Input
              className="singleInputR"
              size="large"
              placeholder="Email Address"
              value={this.state.email}
              onChange={(e) => {
                this.setState({
                  email: e.target.value,
                  isError: false,
                });
              }}
            />
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Input
              className="singleInputL"
              size="large"
              placeholder="Phone Number"
              value={this.state.phone}
              onChange={(e) => {
                this.setState({
                  phone: e.target.value,
                  isError: false,
                });
              }}
            />
          </Col>
        </Row>
        <Row gutter={4} className="inputLine">
          <Col lg={12} md={12} sm={24} xs={24} style={{ marginBottom: "10px" }}>
            <Input
              className="singleInputR"
              size="large"
              placeholder="Company Name"
              value={this.state.company}
              onChange={(e) => {
                this.setState({
                  company: e.target.value,
                  isError: false,
                });
              }}
            />
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Input
              className="singleInputL"
              size="large"
              placeholder="Job Title"
              value={this.state.job}
              onChange={(e) => {
                this.setState({
                  job: e.target.value,
                  isError: false,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <div>
            <TextArea
              value={this.state.msg}
              onChange={(e) => {
                this.setState({
                  msg: e.target.value,
                  isError: false,
                });
              }}
              className="singletextArea"
              rows={7}
            />
          </div>
        </Row>
        <p>
          Agylz specialist will contact you about or products and services. You
          may unsubscribe from these communications, as our privacy practices
          and commitment to protecting your privacy states. Check out our
          <Link to="/privacy"> Privacy Policy.</Link>
        </p>
        <div className="ButtonSend">
          <button onClick={this.showModal} className="btnMobile">
            Send
          </button>
        </div>
      </div>
    );
  }
}

import styled from "styled-components";

export const Button = styled.button`
  background-color: ${props =>
    !props.disabled ? props.theme.brand.button : props.theme.disabled};
  color: white;
  border-radius: 4px;
  border-style: none;
  padding: 13px 70px;
`;

import React from "react";
import "./pricing.styles.scss";
import Page from "../../components/layout";
import Background from "../../components/Pricing/pricing-background";
import Packages from "../../components/Pricing/pricing-packages";
import Masthead from "../../components/Masthead/index";
class Pricing extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Page>
        <Masthead
          title="Plan options"
          desc="Explore which plan is right for you, we also can customize your plan based on your needs"
        />
        {/* <Background /> */}

        <Packages />
      </Page>
    );
  }
}

export default Pricing;

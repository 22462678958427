import React, { Component } from "react";
import Info from "./Info";
import Massage from "./Massage";
import { Col, Row } from "antd";
import "./contactinfo.styles.scss";

export default class ContactInfo extends Component {
  render() {
    return (
      <div className="contactInfo">
        <Row>
          {/* <Col lg={8} md={24} sm={24} xs={24} style={{ marginBottom: "20px" }}>
            <Info />
          </Col> */}
          <Col lg={4} md={24} sm={24} xs={24}></Col>
          <Col lg={16} md={24} sm={24} xs={24}>
            <Massage />
            {/* <Info /> */}
          </Col>
          <Col lg={4} md={24} sm={24} xs={24}></Col>
        </Row>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Card, List } from "antd";
import errorImg from "../../../../../Images/error.png";
import "./cards.styles.scss";
import check from "../../../../../Images/ic_check.png";
import BrandedButton from "../../../../brandedButton";
import { Link } from "react-router-dom";
const data = [
  {
    type: "Basic",
    price: "49",
    feature1: "Create Concepts",
    feature2: "Idea Management",
    feature3: "Share Files",
    feature4: "Concept Status",
    feature5: "Dashboard",
    feature6: "Search",
    feature7: "Calendar",
    feature8: "Chat",
    feature9: "Digital worker",
    feature10: "Cloud storage",
    feature11: "Meeting agenda",
    // feature12: "Audio and video",
    feature13: "Workshop",
    feature14: "Sprint",
    feature15: "Digital worker - Pro",
    feature16: "Live audience interaction",
    feature17: "Financial - ROI",
    feature18: "Task manager",
    feature19: "Proof of concept management",
    desc1: "Set Timeline , expectations and invite your team",
    desc2: "Collect Ideas , Vote , Like And Comment",
    desc3: "Attach PDF , Pictures , PowerPoint , etc",
    desc4: "Timeline, phases & team participation",
    desc5: "Requests, concepts, notifications",
    desc6: "Search for concepts and people",
    desc7: "See everything in one calendar",
    desc8: "Teams, group and one on one",
    desc9: "will send emails and schedule meetings",
    desc10: "10 GB storage per user",
    desc11: "suggested, template, create your own",
    // desc12: "10 Hrs audio and video",
    desc13: "Up to 3 workshop sessions",
    desc14: "Up to 3 sprint sessions",
    desc15: "Build presentations, backlogs and feedback",
    desc16: "surveys, focus groups and voting",
    desc17: "cost and value sheets",
    desc18: "Create , assign and track tasks.",
    desc19: "Proof of concept plan, tasks, timeline, team & status",
    x1: true,
    x2: true,
    x3: true,
    x4: true,
    x5: true,
    x6: true,
    x7: true,
    x8: true,
    x9: true,
    x10: true,
    x11: true,
    // x12: true,
    x13: true,
    x14: true,
    x15: false,
    x16: false,
    x17: false,
    x18: true,
    x19: false,
  },
  {
    type: "Premium",
    price: "70",
    feature1: "Create Concepts",
    feature2: "Idea Management",
    feature3: "Share Files",
    feature4: "Concept Status",
    feature5: "Dashboard",
    feature6: "Search",
    feature7: "Calendar",
    feature8: "Chat",
    feature9: "Digital worker",
    feature10: "Cloud storage",
    feature11: "Meeting agenda",
    // feature12: "Audio and video",
    feature13: "Workshop",
    feature14: "Sprint",
    feature15: "Digital worker - Pro",
    feature16: "Live audience interaction",
    feature17: "Financial - ROI",
    feature18: "Task manager",
    feature19: "Proof of concept management",
    desc1: "Set Timeline , expectations and invite your team",
    desc2: "Collect Ideas , Vote , Like And Comment",
    desc3: "Attach PDF , Pictures , PowerPoint , etc",
    desc4: "Timeline, phases & team participation",
    desc5: "Requests, concepts, notifications",
    desc6: "Search for concepts and people",
    desc7: "See everything in one calendar",
    desc8: "Teams, group and one on one",
    desc9: "will send emails and schedule meetings",
    desc10: "10 GB storage per user",
    desc11: "suggested, template, create your own",
    // desc12: "Unlimited Hrs audio and video",
    desc13: "Digital workspace, sticky notes & whiteboard",
    desc14: "Backlog, grouping, categories & phases",
    desc15: "Build presentations, backlogs and feedback",
    desc16: "surveys, focus groups and voting",
    desc17: "cost and value sheets",
    desc18: "Create , assign and track tasks.",
    desc19: "Proof of concept plan, tasks, timeline, team & status",
    x1: true,
    x2: true,
    x3: true,
    x4: true,
    x5: true,
    x6: true,
    x7: true,
    x8: true,
    x9: true,
    x10: true,
    x11: true,
    // x12: true,
    x13: true,
    x14: true,
    x15: true,
    x16: true,
    x17: false,
    x18: true,
    x19: false,
  },
  {
    type: "Business",
    price: "85",
    feature1: "Create Concepts",
    feature2: "Idea Management",
    feature3: "Share Files",
    feature4: "Concept Status",
    feature5: "Dashboard",
    feature6: "Search",
    feature7: "Calendar",
    feature8: "Chat",
    feature9: "Digital worker",
    feature10: "Cloud storage",
    feature11: "Meeting agenda",
    // feature12: "Audio and video",
    feature13: "Workshop",
    feature14: "Sprint",
    feature15: "Digital worker - Pro",
    feature16: "Live audience interaction",
    feature17: "Financial - ROI",
    feature18: "Task manager",
    feature19: "Proof of concept management",
    desc1: "Set Timeline , expectations and invite your team",
    desc2: "Collect Ideas , Vote , Like And Comment",
    desc3: "Attach PDF , Pictures , PowerPoint , etc",
    desc4: "Timeline, phases & team participation",
    desc5: "Requests, concepts, notifications",
    desc6: "Search for concepts and people",
    desc7: "See everything in one calendar",
    desc8: "Teams, group and one on one",
    desc9: "will send emails and schedule meetings",
    desc10: "10 GB storage per user",
    desc11: "suggested, template, create your own",
    // desc12: "Unlimited Hrs audio and video",
    desc13: "Digital workspace, sticky notes & whiteboard",
    desc14: "Backlog, grouping, categories & phases",
    desc15: "Build presentations, backlogs and feedback",
    desc16: "surveys, focus groups and voting",
    desc17: "cost and value sheets",
    desc18: "Create , assign and track tasks.",
    desc19: "Proof of concept plan, tasks, timeline, team & status",
    x1: true,
    x2: true,
    x3: true,
    x4: true,
    x5: true,
    x6: true,
    x7: true,
    x8: true,
    x9: true,
    x10: true,
    x11: true,
    // x12: true,
    x13: true,
    x14: true,
    x15: true,
    x16: true,
    x17: true,
    x18: true,
    x19: false,
  },
  {
    type: "Enterprise",
    price: "100",
    feature1: "Create Concepts",
    feature2: "Idea Management",
    feature3: "Share Files",
    feature4: "Concept Status",
    feature5: "Dashboard",
    feature6: "Search",
    feature7: "Calendar",
    feature8: "Chat",
    feature9: "Digital worker",
    feature10: "Cloud storage",
    feature11: "Meeting agenda",
    // feature12: "Audio and video",
    feature13: "Workshop",
    feature14: "Sprint",
    feature15: "Digital worker - Pro",
    feature16: "Live audience interaction",
    feature17: "Financial - ROI",
    feature18: "Task manager",
    feature19: "Proof of concept management",
    desc1: "Set Timeline , expectations and invite your team",
    desc2: "Collect Ideas , Vote , Like And Comment",
    desc3: "Attach PDF , Pictures , PowerPoint , etc",
    desc4: "Timeline, phases & team participation",
    desc5: "Requests, concepts, notifications",
    desc6: "Search for concepts and people",
    desc7: "See everything in one calendar",
    desc8: "Teams, group and one on one",
    desc9: "will send emails and schedule meetings",
    desc10: "10 GB storage per user",
    desc11: "suggested, template, create your own",
    // desc12: "Unlimited Hrs audio and video",
    desc13: "Digital workspace, sticky notes & whiteboard",
    desc14: "Backlog, grouping, categories & phases",
    desc15: "Build presentations, backlogs and feedback",
    desc16: "surveys, focus groups and voting",
    desc17: "cost and value sheets",
    desc18: "Create , assign and track tasks.",
    desc19: "Proof of concept plan, tasks, timeline, team & status",
    x1: true,
    x2: true,
    x3: true,
    x4: true,
    x5: true,
    x6: true,
    x7: true,
    x8: true,
    x9: true,
    x10: true,
    x11: true,
    // x12: true,
    x13: true,
    x14: true,
    x15: true,
    x16: true,
    x17: true,
    x18: true,
    x19: true,
  },
];

export default class Cards extends Component {
  render() {
    return (
      <div>
        <List
          grid={{
            gutter: 16,
            column: 4,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 4,
            xxl: 4,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item style={{ padding: 10 }}>
              <Card>
                <div>
                  <h5 className="type" style={{ marginTop: 30 }}>
                    {item.type}
                  </h5>
                  {/* <div>
                    <img src={circle} className="mainimg" />
                    <div className="maintext">
                      <h1 className="price">{item.price}</h1>
                      <p className="month"> PER MONTH</p>
                    </div>
                  </div> */}
                  <div style={{ height: 145, marginTop: 14, marginBottom: 68 }}>
                    <div style={{ marginBottom: 2, textAlign: "left" }}>
                      {item.x1 && (
                        <img style={{ paddingRight: 13 }} src={check} />
                      )}
                      {!item.x1 && (
                        <img
                          style={{ paddingRight: 13 }}
                          src={errorImg}
                          width={29}
                        />
                      )}
                      <span style={{ display: "inline" }}>{item.feature1}</span>
                      <br />
                      <small>{item.desc1}</small>
                    </div>
                    <div style={{ marginBottom: 2, textAlign: "left" }}>
                      {item.x2 && (
                        <img style={{ paddingRight: 13 }} src={check} />
                      )}
                      {!item.x2 && (
                        <img
                          style={{ paddingRight: 13 }}
                          src={errorImg}
                          width={29}
                        />
                      )}
                      <span style={{ display: "inline" }}>{item.feature2}</span>
                      <br />
                      <small>{item.desc2}</small>
                    </div>
                    <div style={{ marginBottom: 2, textAlign: "left" }}>
                      {item.x3 && (
                        <img style={{ paddingRight: 13 }} src={check} />
                      )}
                      {!item.x3 && (
                        <img
                          style={{ paddingRight: 13 }}
                          src={errorImg}
                          width={29}
                        />
                      )}
                      <span style={{ display: "inline" }}>{item.feature3}</span>
                      <br />
                      <small>{item.desc3}</small>
                    </div>
                    {item.feature4 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x4 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x4 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature4}
                        </span>
                        <br />
                        <small>{item.desc4}</small>
                      </div>
                    ) : null}

                    {item.feature5 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x5 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x5 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature5}
                        </span>
                        <br />
                        <small>{item.desc5}</small>
                      </div>
                    ) : null}

                    {item.feature6 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x6 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x6 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature6}
                        </span>
                        <br />
                        <small>{item.desc6}</small>
                      </div>
                    ) : null}

                    {item.feature7 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x7 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x7 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature7}
                        </span>
                        <br />
                        <small>{item.desc7}</small>
                      </div>
                    ) : null}

                    {item.feature8 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x8 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x8 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature8}
                        </span>
                        <br />
                        <small>{item.desc8}</small>
                      </div>
                    ) : null}

                    {item.feature9 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x9 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x9 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature9}
                        </span>
                        <br />
                        <small>{item.desc9}</small>
                      </div>
                    ) : null}

                    {item.feature10 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x10 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x10 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature10}
                        </span>
                        <br />
                        <small>{item.desc10}</small>
                      </div>
                    ) : null}

                    {item.feature11 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x11 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x11 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature11}
                        </span>
                        <br />
                        <small>{item.desc11}</small>
                      </div>
                    ) : null}

                    {/*                     

                    {item.feature12 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x12 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x12 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature12}
                        </span>
                        <br />
                        <small>{item.desc12}</small>
                      </div>
                    ) : null}

                     */}

                    {item.feature13 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x13 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x13 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature13}
                        </span>
                        <br />
                        <small>{item.desc13}</small>
                      </div>
                    ) : null}

                    {item.feature14 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x14 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x14 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature14}
                        </span>
                        <br />
                        <small>{item.desc14}</small>
                      </div>
                    ) : null}

                    {item.feature15 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x15 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x15 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature15}
                        </span>
                        <br />
                        <small>{item.desc15}</small>
                      </div>
                    ) : null}

                    {item.feature16 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x16 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x16 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature16}
                        </span>
                        <br />
                        <small>{item.desc16}</small>
                      </div>
                    ) : null}

                    {item.feature17 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x17 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x17 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature17}
                        </span>
                        <br />
                        <small>{item.desc17}</small>
                      </div>
                    ) : null}

                    {item.feature18 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x18 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x18 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature18}
                        </span>
                        <br />
                        <small>{item.desc18}</small>
                      </div>
                    ) : null}

                    {item.feature19 ? (
                      <div style={{ marginBottom: 2, textAlign: "left" }}>
                        {item.x19 && (
                          <img style={{ paddingRight: 13 }} src={check} />
                        )}
                        {!item.x19 && (
                          <img
                            style={{ paddingRight: 13 }}
                            src={errorImg}
                            width={29}
                          />
                        )}
                        <span style={{ display: "inline" }}>
                          {item.feature19}
                        </span>
                        <br />
                        <small>{item.desc19}</small>
                      </div>
                    ) : null}
                  </div>

                  {/* <div>
                    <List
                      size="small"
                      dataSource={data}
                      renderItem={item => (
                        <div>
                          <img src={check} />
                          <span style={{ display: "inline" }}>{item.feature}</span>
                        </div>
                      )}
                    />
                  </div> */}
                </div>
                <div style={{ marginTop: 20 }}>
                  <Link to="/contact">
                    <BrandedButton
                      className="customBtn"
                      children="Book A Demo"
                      style={{ marginTop: 20 }}
                    />
                  </Link>
                </div>
              </Card>
            </List.Item>
          )}
        />
        ,
      </div>
    );
  }
}

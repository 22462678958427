import React, { Component } from "react";
import "./style.css";
import { Box } from "../../../styles/theme";
import Button from "../../brandedButton/index";
import { Link } from "react-router-dom";
class ProductHeader extends Component {
  render() {
    return (
      <div className="productHeader">
        <Box>
          <div className="textContainerProduct">
            <h1 className="titleInnProduct">{this.props.title}</h1>

            <p className="text" style={{ marginTop: 20, marginBottom: 20 }}>
              {this.props.desc}
            </p>
            <div className="center">
              <Link to="/contact">
                <Button children="Get Started" />
              </Link>
            </div>
          </div>
        </Box>
      </div>
    );
  }
}

export default ProductHeader;

import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/homepage/homepage.component";
import Product from "./pages/productpage/product.component";
import Pricing from "./pages/pricingpage/pricing.component";
import Contact from "./pages/contactpage/contact.component";
import theme from "./styles/theme";
import "./App.css";
import Terms from "./pages/terms/terms.component";
import Privacy from "./pages/privacy/privacy.component";
import Eula from "./pages/eula/eula.component";
// import DeviceOrientation, { Orientation } from "react-screen-orientation";
// import ReactNotification from "react-notifications-component";
import Zoom from "./pages/zoom/zoom.component";
import ForSchool from "./pages/forschoolspage/forSchool.component";
import ProgramForm from "./pages/programForm";

function App() {
  return (
    <div className="App">
      {/* <DeviceOrientation lockOrientation={"landscape"}> */}
      <ThemeProvider theme={theme}>
        {/* <GlobalApp> */}
        <BrowserRouter>
          <Routes>
            <Route index exact path="/" element={<Home />} />
            <Route exact path="/product" element={<Product />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/forstudents" element={<ForSchool />} />
            <Route exact path="/eula" element={<Eula />} />
            <Route exact path="/zoom" element={<Zoom />} />
            <Route exact path="/programform" element={<ProgramForm />} />
          </Routes>
        </BrowserRouter>
        {/* </GlobalApp> */}
      </ThemeProvider>
      {/* </DeviceOrientation> */}
    </div>
  );
}

export default App;

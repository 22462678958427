import React, { Component } from "react";
import { Layout, Drawer } from "antd";
import { Link } from "react-router-dom";
import "./layout.styles.scss";
import LeftMenu from "./leftmenu";
import RightMenu from "./rightmenu";
import FooterList from "./footer";
import logo from "../../Images/img_brand_logo.png";
import menuImge from "../../Images/img_menu_slide.png";

const { Header, Content, Footer } = Layout;

var prevScrollpos = window.pageYOffset;

class Page extends Component {
  state = { visible: false, top: 0 };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    window.onscroll = (ev) => {
      var currentScrollPos = window.pageYOffset;

      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 220) {
        if (prevScrollpos > currentScrollPos) {
          this.setState({
            top: 0,
          });
        } else {
          this.setState({
            top: -64,
          });
        }
      }
      prevScrollpos = currentScrollPos;
    };
  }

  render() {
    const { children } = this.props;
    return (
      <div>
        <Layout className="layout">
          <Header className="header" style={{ top: this.state.top }}>
            <div className="logo" style={{ width: 105 }}>
              <Link to="/">
                <img src={logo} alt="logo" style={{ width: 105 }} />
              </Link>
            </div>

            <div className="left-menu">
              <LeftMenu {...this.props} />
            </div>

            <div className="right-menu">
              <RightMenu {...this.props} />
            </div>
            <div className="drawer">
              <div onClick={this.showDrawer}>
                <img src={menuImge} />
              </div>
            </div>
          </Header>
          <Content style={{ width: "100%" }}>{children}</Content>
          <Footer className="footer" style={{ backgroundColor: "#005075" }}>
            <FooterList />
          </Footer>
          <Drawer
            title={<img src={logo} width={100} />}
            placement="right"
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
            getContainer={false}
          >
            <div className="rightList">
              <ul>
                <li>
                  {" "}
                  <Link to="/">Home</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/product">Product</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/forstudents">For Students</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/pricing">Plans</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  {" "}
                  <a href="https://app.agylz.net/login">Login</a>
                </li>
                <li>
                  {" "}
                  <a href="https://app.agylz.net/register">Register</a>
                </li>
              </ul>
            </div>
          </Drawer>
        </Layout>
      </div>
    );
  }
}

export default Page;

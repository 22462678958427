import React from "react";
import "./style.scss";
import YouTube from "react-youtube-embed";
const Video = () => (
  <div>
    {/* <video
      id="background-video"
      src={require("../../../Videos/agylz.mp4")}
      autoPlay
      muted
      controls={true}
      loop
    /> */}
    {/* <ReactPlayer url="https://www.youtube.com/watch?v=ug50zmP9I7s" /> */}
    <YouTube id="AbyqFiWU39A" />
  </div>
);
export default Video;

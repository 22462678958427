import React from "react";
import "./forSchool.styles.scss";
import Page from "../../components/layout";
import ForSchoolHeader from "../../components/forSchool/forSchool-header/index";
import ForSchoolInspire from "../../components/forSchool/forSchool-inspire/index";
import ForSchoolLifeCycle from "../../components/forSchool/forSchool-lifecycle";
import ForSchoolDetails from "../../components/forSchool/forSchool-details";
import ForSchoolGetReady from "../../components/forSchool/forSchool-get-ready";
class ForSchool extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Page>
        <ForSchoolHeader
          title="Product innovation for tech students"
          desc="Agylz is a product innovation and design thinking program  with a mobile app that focuses on  building the future tech workforce of America."
        />
        <ForSchoolInspire />
        <ForSchoolLifeCycle />
        <ForSchoolDetails />
        <ForSchoolGetReady />
      </Page>
    );
  }
}
export default ForSchool;

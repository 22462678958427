import React, { Component } from "react";
import "./lifeCycle.styles.scss";
import { List } from "antd";
import cycle from "../../../Images/img_product_cycle.jpg";
import { Header } from "../../../styles/theme";

const data = [
  {
    title: "1. Drop",
    color: "#005075",
    feature1: "• Idea management.",
    feature2: "• Knowledge management.",
    feature3: "• Internal social media",
  },
  {
    title: "2. Discover",
    color: "#FBAB18",
    feature1: "• Workshops",
    feature2: "• User journey",
    feature3: "• Interactive meetings.",
  },
  {
    title: "3. Diagnose",
    color: "#138FD2",
    feature1: "• Focus groups.",
    feature2: "• Surveys.",
    feature3: "• Live Voting.",
  },
  {
    title: "4. Dive",
    color: "#4B0086",
    feature1: "• Finance/ROI.",
    feature2: "• Cost.",
    feature3: "• Income.",
  },
  {
    title: "5. Direction",
    color: "#6EBF49",
    feature1: "• Roadmapping.",
    feature2: "• Product features.",
    feature3: "• Prioritization.",
  },
  {
    title: "6. Deliver",
    color: "#A60F22",
    feature1: "• Proof of concept.",
    feature2: "• Product backlog",
    feature3: "• Tracking.",
  },
];

export default class LifeCycle extends Component {
  render() {
    return (
      <div className="lifeCycleHeader">
        <div className="discoverWrapperLifeCycle">
          <Header>Process Life Cycle</Header>
          <p>
            Product innovation lifecycle into 6 steps with interactive
            activities to deliver better, faster and cheaper.
          </p>
          <div style={{ marginTop: 80 }} className="lifecycle">
            <img
              alt="process life cycle"
              className="minMedia"
              src={cycle}
              width={300}
              style={{}}
            />
            <div
              style={{ width: "55%", float: "right" }}
              className="lifecyclelists"
            >
              <List
                className="mt30"
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xg: 2,
                }}
                dataSource={data}
                renderItem={(item) => (
                  <List.Item>
                    <div className="centerTxt mr30">
                      <h2 className="title fs30" style={{ color: item.color }}>
                        {item.title}
                      </h2>
                      <h4 className="feat">{item.feature1}</h4>
                      <h4 className="feat">{item.feature2}</h4>
                      <h4 className="feat">{item.feature3}</h4>
                      <h4 className="feat">{item.feature4}</h4>
                      {/* <div>
                        {item.feature.map((feat, index) => (
                          <p key={`_${index}`}> . {feat}</p>
                        ))}
                      </div> */}
                    </div>
                  </List.Item>
                )}
              />
              ,
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";
import "./inspireProduct.styles.scss";
import forschool from "../../../Images/forschool.jpg";
import { Header, Box } from "../../../styles/theme";

class ForSchoolInspire extends React.Component {
  render() {
    return (
      <div className="inspireHeader" style={{ marginTop: 10 }}>
        <Header> Program tour </Header>
        <img
          alt="forschools"
          height="auto"
          width="80%"
          src={forschool}
          className="productImg"
          style={{ minHeight: "auto", padding: "5px", margin: "20px 0px" }}
        />
        <div width="80%" style={{ alignItems: "center", padding: "25px" }}>
          <p
            style={{
              fontSize: "24px",
              textAlign: "start",
              margin: "auto",
              width: "80%",
            }}
          >
            A program that provides an interactive, fun, immersive and tech
            learning development experience. <br />
            Using processes like product management, design thinking, agile and
            combine them into 6 steps journey that takes students into an
            environment where the focus is mostly on developing real-world
            skills, such as problem-solving, building tech products from scratch
            and encourage teamwork.
            <br />
            <br />
            The immersion and engagement students gain throughout the program
            dramatically increases their ability to learn, research, think
            outside the box, pitch and allow them to be ready for the workforce
            environment and make better choices for their careers.
          </p>
        </div>
        {/* </Box> */}
      </div>
    );
  }
}

export default ForSchoolInspire;

import React from "react";
import "./getReady.styles.scss";
import button from "../../brandedButton/index";
import { Header, Box } from "../../../styles/theme";
import { Button } from "../../brandedButton/style";
import { Link } from "react-router-dom";
class ProductGetReady extends React.Component {
  render() {
    return (
      <div className="inspireHeader">
        <Box>
          <Header>Transform your workplace</Header>
          <p>
            Collaboration that solve business problems and move product
            innovation forward
          </p>
        </Box>
        <Link to="/contact">
          <Button>Get Started</Button>
        </Link>
      </div>
    );
  }
}

export default ProductGetReady;

import React from "react";
import "./style.scss";
import BrandedButton from "../../brandedButton/index";
import { Box } from "../../../styles/theme";
import { Link } from "react-router-dom";
import agylzRow from "../../../Images/bg-mobile.jpg";
import agylzWeb from "../../../Images/header.jpg";
const Innovation = () => (
  <div className="innovation">
    <div className="web">
      {/* <video
        id="background-video"
        src={require("../../../Videos/video.mp4")}
        autoPlay
        muted
        loop
      /> */}
      <img src={agylzWeb} id="background-video" />
    </div>

    <div className="mobile">
      <img src={agylzRow} className="imgMobile" />
    </div>
    <div className="innovation-container">
      <Box>
        <div className="textcontainer">
          <h2 className="titleInn animated fadeIn slower mobileTITLE">
            Product management platform <br /> with AI
            consultant <br /> for product development, hackathon <br /> and
            proof of concepts
          </h2>
          <div className="web">
            <p className="text animated fadeIn slower mediaNone">
              Empower your team to deliver an innovative solutions in few days
              in an agile, productive and interactive way with a help of a
              digital bot. Capture ideas, run virtual and live workshops, create
              meetings agendas, Sprint backlogs, ROI, conduct focus groups,
              build roadmaps and deliver proof of concept in a fast pace with
              high visibility to status, progress and team participation.
            </p>
          </div>
          <div className="mobile">
            <p className="text animated fadeIn slower mediaNone mobileTXT">
              Empower your team to deliver an innovative solutions in few days
              in an agile, productive and interactive way with a help of a
              digital bot. Capture ideas, run virtual and live workshops, create
              meetings agendas, Sprint backlogs, ROI, conduct focus groups and
              build proof of concept in a fast pace with high visibility to
              status, progress and team participation.
            </p>
          </div>
          <div className="center animated fadeIn slower displayNone">
            <Link to="contact">
              {/* <BrandedButton className="btnMobile" children="Get started" /> */}
              <button className="btnMobile">Get Started</button>
            </Link>
          </div>
        </div>
      </Box>
    </div>
  </div>
);

export default Innovation;

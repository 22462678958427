import React from "react";
import "./style.scss";
import { Row, Col } from "antd";
import schedule from "../../../Images/img_home_schaduel.png";
import contact from "../../../Images/img_contactus.png";
import free from "../../../Images/img_trail.png";
import { Box } from "../../../styles/theme";
import { Link } from "react-router-dom";
var FA = require("react-fontawesome");
class SolveProblems extends React.Component {
  render() {
    return (
      <div className="solveproblems">
        <Box>
          <Row className="content">
            <h2 style={{ marginBottom: 40 }}>
              Ready to solve problems and drive innovation in your workplace?
            </h2>

            <Col span={6} lg={8} md={8} sm={24} xs={24}>
              {/* <a className="icons-orange">
                <FA
                  name="linkedin"
                  className="icons-orange-icon"
                  // style={{
                  //   color: "white",
                  //   fontSize: 100
                  // }}
                />
              </a> */}
              <Link to="/contact">
                <img
                  className="resTop"
                  src={schedule}
                  width={100}
                  style={{ marginTop: 10 }}
                />
                <h4 className="imgHead">SCHEDULE A DEMO</h4>
                <p className="" style={{ textAlign: "center", color: "white" }}>
                  {" "}
                  Select a time that works for you for <br /> a live demo
                  and we'll do the rest.
                </p>
              </Link>
            </Col>
            <Col
              span={6}
              lg={8}
              md={8}
              sm={24}
              xs={24}
              style={{ textAlign: "center" }}
            >
              <Link to="/contact">
                <img
                  className="resTop"
                  src={contact}
                  width={100}
                  style={{ marginTop: 10 }}
                />
                <h4 className="imgHead">CONTACT US</h4>
                <p className="" style={{ textAlign: "center", color: "white" }}>
                  {" "}
                  Have a general question or need to speak to a specialist.
                </p>
              </Link>
            </Col>
            <Col span={6} lg={8} md={8} sm={24} xs={24}>
              <Link to="/contact">
                <img
                  className="resTop"
                  src={free}
                  width={100}
                  style={{ marginTop: 10 }}
                />
                <h4 className="imgHead">FREE TRIAL</h4>
                <p className="" style={{ textAlign: "center", color: "white" }}>
                  Try Agylz for free for 30 days
                </p>
              </Link>
            </Col>
          </Row>
        </Box>
      </div>
    );
  }
}

export default SolveProblems;

import React, { Component } from "react";
import "./style.css";
import { Box } from "../../styles/theme";
import Button from "../../components/brandedButton/index";

class Masthead extends Component {
  render() {
    return (
      <div className="productHeader">
        <Box>
          <div className="textContainerProduct">
            <h1 className="titleInnProduct">{this.props.title}</h1>

            <p className="text" style={{ marginTop: 20, marginBottom: 20 }}>
              {this.props.desc}
            </p>
            {this.props.isBtn && (
              <div className="center">
                <Button children="Get Started" />
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
}

export default Masthead;

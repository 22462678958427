import React from "react";
import "./sayhello.styles.scss";
import { Header, Box } from "../../../styles/theme";

class SayHello extends React.Component {
  render() {
    return (
      <div className="findAway">
        {/* <Box>
          <Header>Don’t be a stranger, Just say Hello! </Header>
          <p>
            Have a questions, feedback or advice? Feel free to get in touch with
            us. Our team of innovation experts are waiting to help. Complete the
            following form to learn more, schedule a demo or call us.
          </p>
        </Box> */}
      </div>
    );
  }
}

export default SayHello;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/antd/dist/antd.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n  background-color: white;\n  /* font-family: \"SF Display\"; */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-size: 18px;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5 {\n  font-weight: bold;\n  color: white;\n  margin-bottom: 0px;\n}\nh1 {\n  font-size: 40px;\n}\nh2 {\n  font-size: 40px;\n}\nh3 {\n  font-size: 36px;\n  font-weight: normal;\n  color: white;\n}\nh4 {\n  font-size: 28px;\n}\nh5 {\n  font-size: 32px;\n}\np {\n  font-size: 20px;\n}\nspan {\n  font-size: 18px;\n}\n@media (min-width: 320px) and (max-width: 780px) {\n  h1,\n  h2,\n  h3 {\n    font-size: 36px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,UAAU;EACV,YAAY;EACZ,uBAAuB;EACvB,+BAA+B;EAC/B,mCAAmC;EACnC,kCAAkC;EAClC,eAAe;AACjB;;AAEA;;;;;EAKE,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE;;;IAGE,eAAe;EACjB;AACF","sourcesContent":["@import \"~antd/dist/antd.css\";\nbody {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n  background-color: white;\n  /* font-family: \"SF Display\"; */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-size: 18px;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5 {\n  font-weight: bold;\n  color: white;\n  margin-bottom: 0px;\n}\nh1 {\n  font-size: 40px;\n}\nh2 {\n  font-size: 40px;\n}\nh3 {\n  font-size: 36px;\n  font-weight: normal;\n  color: white;\n}\nh4 {\n  font-size: 28px;\n}\nh5 {\n  font-size: 32px;\n}\np {\n  font-size: 20px;\n}\nspan {\n  font-size: 18px;\n}\n@media (min-width: 320px) and (max-width: 780px) {\n  h1,\n  h2,\n  h3 {\n    font-size: 36px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from "react";
import { Row, Col } from "antd";
import details from "../../../Images/img_product-details.png";
import slider1 from "../../../Images/p1.png";
import slider2 from "../../../Images/p2.png";
import slider3 from "../../../Images/p3.png";
import slider4 from "../../../Images/p4.png";
import slider5 from "../../../Images/p5.png";
// import details_right from "../../../Images/img_details_right.png";
import "./details.styles.scss";
import { NavLink } from "react-router-dom";
class ForSchoolDetails extends Component {
  render() {
    return (
      <section>
        <div
          className="detailsContainer"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="discoverWrapperDetails">
            <Row gutter={16}>
              <Col span={12} xs={24} lg={12} md={12} sm={24} className="center">
                <section>
                  <img src={slider1} className="imageDetails mt20" alt="/" />
                </section>
              </Col>

              <Col span={12} xs={24} lg={12} md={12}>
                <section
                  style={{ paddingLeft: 93, paddingTop: 55, marginBottom: 40 }}
                  className="details"
                >
                  <h5 className="detailsHeader">Drop</h5>
                  <p className="detailsPragh" style={{ paddingTop: 30 }}>
                    Introduce a list of challenges and new opportunities to
                    engage students in ideation sessions, the objective is to
                    generate and capture all the students bright ideas, build an
                    idea backlog and get them to think outside the box
                  </p>
                  <NavLink to="/programform" style={{ marginBottom: 50 }}>
                    <p style={{ color: "#138FD2" }}>Get Started</p>
                  </NavLink>
                </section>
              </Col>
            </Row>
          </div>
        </div>
        <div className="detailsContainer" style={{ backgroundColor: "white" }}>
          <div className="discoverWrapperDetails">
            <Row gutter={16} className="planBlock">
              <Col span={12} xs={24} lg={12} md={12} sm={24}>
                <section style={{ float: "left", marginBottom: 40 }}>
                  <h5 className="detailsHeader" style={{ marginTop: 55 }}>
                    Discover
                  </h5>
                  <p className="detailsPragh" style={{ paddingTop: 30 }}>
                    Iterative process and workshops that facilitate the
                    interaction between students to redefine their
                    solutions/ideas, understand the user journey, discuss
                    solution features list and product value proposition.
                  </p>
                  <NavLink exact to="/programform" style={{ marginBottom: 50 }}>
                    <p style={{ color: "#138FD2" }}>Get Started</p>
                  </NavLink>
                </section>
              </Col>
              <Col span={12} xs={24} lg={12} md={12} className="center">
                <section>
                  <img src={slider2} className="imageDetails" alt="/" />
                </section>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className="detailsContainer"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="discoverWrapperDetails">
            <Row gutter={16}>
              <Col span={12} xs={24} lg={12} md={12} className="center">
                <section>
                  <img src={slider3} className="imageDetails" alt="/" />
                </section>
              </Col>

              <Col span={12} xs={24} lg={12} md={12}>
                <section
                  style={{ paddingLeft: 93, paddingTop: 55, marginBottom: 40 }}
                  className="details"
                >
                  <h5 className="detailsHeader">Diagnose</h5>
                  <p className="detailsPragh" style={{ paddingTop: 30 }}>
                    Surveys and focus groups that bring the expert knowledge and
                    users feedback to help students make better decisions and
                    endless improvement to their products.
                  </p>
                  <NavLink exact to="/programform" style={{ marginBottom: 50 }}>
                    <p style={{ color: "#138FD2" }}>Get Started</p>
                  </NavLink>
                </section>
              </Col>
            </Row>
          </div>
        </div>
        <div className="detailsContainer" style={{ backgroundColor: "white" }}>
          <div className="discoverWrapperDetails">
            <Row gutter={16} className="planBlock">
              <Col span={12} xs={24} lg={12} md={12}>
                <section
                  style={{ float: "left", paddingTop: 55, marginBottom: 40 }}
                >
                  <h5 className="detailsHeader">Dive</h5>
                  <p className="detailsPragh" style={{ paddingTop: 30 }}>
                    Product financial components, cost, income and the ratio
                    between them to simply help students understand that
                    financials is one of the most important factors in the
                    decision making process
                  </p>
                  <NavLink to="/programform" style={{ marginBottom: 50 }}>
                    <p style={{ color: "#138FD2" }}>Get Started</p>
                  </NavLink>
                </section>
              </Col>
              <Col span={12} xs={24} lg={12} md={12} className="center">
                <section>
                  <img src={slider5} className="imageDetails" alt="/" />
                </section>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className="detailsContainer"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="discoverWrapperDetails">
            <Row gutter={16} className="planBlock">
              <Col span={12} xs={24} lg={12} md={12}>
                <section
                  style={{ float: "left", paddingTop: 55, marginBottom: 40 }}
                >
                  <h5 className="detailsHeader">Direction</h5>
                  <p className="detailsPragh" style={{ paddingTop: 30 }}>
                    This step where students will build their product roadmap to
                    have a shared source of truth that outlines the vision,
                    direction, priorities, and progress of a product over time.
                  </p>
                  <NavLink to="/programform" style={{ marginBottom: 50 }}>
                    <p style={{ color: "#138FD2" }}>Get Started</p>
                  </NavLink>
                </section>
              </Col>
              <Col span={12} xs={24} lg={12} md={12} className="center">
                <section>
                  <img src={slider4} className="imageDetails" alt="/" />
                </section>
              </Col>
            </Row>
          </div>
        </div>
        <div className="detailsContainer" style={{ backgroundColor: "white" }}>
          <div className="discoverWrapperDetails">
            <Row gutter={16}>
              <Col span={12} xs={24} lg={12} md={12} className="center">
                <section>
                  <img src={slider5} className="imageDetails" alt="/" />
                </section>
              </Col>

              <Col span={12} xs={24} lg={12} md={12}>
                <section
                  style={{ paddingLeft: 93, paddingTop: 55, marginBottom: 40 }}
                  className="details"
                >
                  <h5 className="detailsHeader">Deliver</h5>
                  <p className="detailsPragh" style={{ paddingTop: 30 }}>
                    Deliver proof of concept products, build product backlogs,
                    assign tasks, set priorities, achieve your goals in one
                    single place with high visibility to status, progress and
                    team participation.
                  </p>
                  <NavLink exact to="/programform" style={{ marginBottom: 50 }}>
                    <p style={{ color: "#138FD2" }}>Get Started</p>
                  </NavLink>
                </section>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    );
  }
}
export default ForSchoolDetails;

import React, { Component } from "react";
import "../../Contact/contact-info/Massage/Massage.styles.scss";
import { Input, Row, Col } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Link } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      error:
        "Error while submitting your form please chec your internet connection and try again",

      success:
        "We received your request and one of our specialists will contact you!",
      isSuccessVisable: false,
    };
  }

  componentDidMount() {
    this.scrollToTheTop();
  }

  scrollToTheTop = () => {
    window.scrollTo(0, 20);
  };

  render() {
    return (
      <div className="fullWidth">
        {this.state.isError && (
          <div className="error" style={{ marginTop: 10, marginBottom: 10 }}>
            {this.state.error}
          </div>
        )}

        {this.state.isSuccessVisable && (
          <div className="success" style={{ marginTop: 10, marginBottom: 10 }}>
            {this.state.success}
          </div>
        )}

        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            school: "",
            age: "",
            about: "",
            passion: "",
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .min(3, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
            lastName: Yup.string()
              .min(3, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
            email: Yup.string().email("Invalid email").required("Required"),
            school: Yup.string().required("required"),
            age: Yup.number()
              .required("required")
              .min(16, "invalid")
              .max(100, "invalid"),
            about: Yup.string()
              .required("required")
              .min(20, "at least 20 character ,we want to know more about you"),
            passion: Yup.string()
              .required("required")
              .min(
                20,
                "at least 20 character ,we want to know more about your passion"
              ),
            phoneNumber: Yup.string().required("required"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);

            let subject = new Date().getFullYear() + " Free Program ";
            let txt = `First Name : ${values.firstName} <br/>  Last Name : ${values.lastName} <br/>  Email :
            ${values.email} <br/>  Mobile Number : ${values.phoneNumber} <br/>  School : ${values.school} <br/>  age: ${values.age} <br/>  About Me: ${values.about} <br/>  My passion:${values.passion}`;
            const data = {
              subject: subject,
              text: txt,
              to: "agylzllc@gmail.com",
              name: `${values.firstName} ${values.lastName}`,
              from: values.email,
            };

            axios
              .post("https://service.agylz.net/api/contact", data)
              .then(() => {
                resetForm();
                this.scrollToTheTop();
                this.setState({
                  isSuccessVisable: true,
                });

                setTimeout(() => {
                  this.setState({ isSuccessVisable: false });
                  setSubmitting(false);
                }, 7000);
              })
              .catch((e) => {
                this.scrollToTheTop();
                this.setState({
                  isError: true,
                });
                setTimeout(() => {
                  this.setState({ isError: false });
                  setSubmitting(false);
                }, 7000);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Row gutter={4} className="inputLine">
                {/* <div className="inputLine"> */}
                <Col
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  style={{ marginBottom: "10px" }}
                >
                  <Input
                    name="firstName"
                    className="singleInputR"
                    size="large"
                    status="error"
                    // status={errors.firstName && "error"}
                    onChange={handleChange}
                    value={values.firstName}
                    placeholder="First Name"
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.firstName && touched.firstName && errors.firstName}
                  </div>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <Input
                    name="lastName"
                    onChange={handleChange}
                    className="singleInputL"
                    size="large"
                    value={values.lastName}
                    placeholder="Last Name"
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.lastName && touched.lastName && errors.lastName}
                  </div>
                </Col>
              </Row>
              <Row gutter={4} className="inputLine">
                <Col
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  style={{ marginBottom: "10px" }}
                >
                  <Input
                    className="singleInputR"
                    size="large"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    placeholder="Email Address"
                    value={values.email}
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.email && touched.email && errors.email}
                  </div>{" "}
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <Input
                    className="singleInputL"
                    size="large"
                    type="tel"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={values.phoneNumber}
                    onChange={handleChange}
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.phoneNumber &&
                      touched.phoneNumber &&
                      errors.phoneNumber}
                  </div>
                </Col>
              </Row>
              <Row gutter={4} className="inputLine">
                <Col
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  style={{ marginBottom: "10px" }}
                >
                  <Input
                    className="singleInputR"
                    size="large"
                    name="school"
                    placeholder="School Name"
                    value={values.school}
                    onChange={handleChange}
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.school && touched.school && errors.school}
                  </div>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <Input
                    className="singleInputL"
                    size="large"
                    min={16}
                    status={errors.age && "error"}
                    placeholder="Age"
                    type="number"
                    name="age"
                    value={values.age}
                    onChange={handleChange}
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.age && touched.age && errors.age}
                  </div>
                </Col>
              </Row>
              <Row>
                <div>
                  <TextArea
                    value={values.about}
                    name="about"
                    onChange={handleChange}
                    placeholder="Tell us more about you"
                    className="singletextArea"
                    rows={7}
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.about && touched.about && errors.about}
                  </div>
                </div>
              </Row>
              <Row>
                <div>
                  <TextArea
                    placeholder="What is your passion?"
                    value={values.passion}
                    name="passion"
                    onChange={handleChange}
                    className="singletextArea"
                    rows={7}
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.passion && touched.passion && errors.passion}
                  </div>
                </div>
              </Row>
              <p>
                Once you submit the application, our specialists will review it
                and will contact you to schedule a virtual meeting to explain
                the program details and requirements. As our privacy practices
                and commitment to protecting your privacy states. Check out our
                <Link to="/privacy"> Privacy Policy.</Link>
              </p>
              <div className="ButtonSend">
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="btnMobile"
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

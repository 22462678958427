import React, { Component } from "react";
import { Button } from "./style";

// import styled from "styled-components";

// const Button = styled.button`
//   background-color: ${props =>
//     !props.disabled ? props.theme.brand.button : props.theme.disabled};
//   color: white;
//   border-radius: 4px;
//   border-style: none;
//   padding: 13px 40px;
// `;

class BrandedButton extends React.Component {
  render() {
    const {
      children,
      styles,
      redirect,
      handleClick,
      handelClick,
      disabled
    } = this.props;
    return (
      <Button
        disabled={disabled}
        style={styles}
        href={redirect}
        onClick={handelClick || handleClick}
      >
        {children}
      </Button>
    );
  }
}

export default BrandedButton;

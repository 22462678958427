import React, { Component } from "react";
import FindAway from "./find-away/index";
import { Link } from "react-router-dom";
import PackagesCard from "./package-card";
import BrandedButton from "../../brandedButton";
import style from "./styles.css";

export default class Packages extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <FindAway />
        <div className="discoverWrapper">
          <PackagesCard />
          <div
            className="descDev"
            style={{ marginTop: 100, paddingBottom: 128 }}
          >
            <p>
              Looking for another plan? <br /> We’re here to help your business
            </p>
            <Link exact to="/contact">
              <BrandedButton
                styles={{ backgroundColor: "#52c41a" }}
                children="Contact Us"
                redirect="/contact"
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

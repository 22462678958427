import React from "react";
import "./inspireProduct.styles.scss";
import product from "../../../Images/img_product.png";
import { Header, Box } from "../../../styles/theme";

class ProductInspire extends React.Component {
  render() {
    return (
      <div className="inspireHeader" style={{ marginTop: 70 }}>
        {/* <Box>
          <Header>Inspire the Next</Header>
          <p>
            Our Process will help you to drive the innovation and creativity
            culture to solve problems
          </p> */}
        <Header> Product Tour </Header>
        <img src={product} className="productImg" style={{}} />
        {/* </Box> */}
      </div>
    );
  }
}

export default ProductInspire;

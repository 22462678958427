import React, { Component } from "react";
import contactbackground from "../../../Images/img_contact_background.png";

export default class ContactBackground extends Component {
  render() {
    return (
      <section
        className="contactbackground"
        style={{
          height: 613,
          backgroundImage: `url(${contactbackground})`
        }}
      ></section>
    );
  }
}

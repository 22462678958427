import React, { Component } from "react";
import background from "../../../Images/img_pricing_background.png";

export default class Background extends Component {
  render() {
    return (
      <section
        className="background"
        style={{
          height: 620,
          backgroundImage: `url(${background})`
        }}
      ></section>
    );
  }
}

import React, { Component } from "react";
import Cards from "../Card";
import { Box } from "../../../../../styles/theme";

export default class Monthly extends Component {
  render() {
    return (
      <Box>
        <Cards />{" "}
      </Box>
    );
  }
}

import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import "./layout.styles.scss";
export default function LeftMenu() {
  const location = useLocation();
  return (
    <Menu
      style={{ lineHeight: "59px" }}
      selectedKeys={[location.pathname]}
      mode="horizontal"
    >
      <Menu.Item key="/">
        <Link to="/">Home</Link>
      </Menu.Item>
      <Menu.Item key="/product">
        <Link to="/product">Product</Link>
      </Menu.Item>
      <Menu.Item key="/forstudents">
        <Link to="/forstudents">For Students</Link>
      </Menu.Item>
      <Menu.Item key="/pricing">
        <Link to="/pricing">Plans</Link>
      </Menu.Item>
      <Menu.Item key="/contact">
        <Link to="/contact">Contact</Link>
      </Menu.Item>
    </Menu>
  );
}

import React from "react";
import "./contact.styles.scss";
import Page from "../../components/layout";
import ContactBackground from "../../components/Contact/contact-background";
import SayHello from "../../components/Contact/say-hello";
import ContactInfo from "../../components/Contact/contact-info";
import { Box } from "../../styles/theme";
import Masthead from "../../components/Masthead/index";
import { Col, Row } from "antd";
import Form from "../../components/forSchool/program-apply/form";
class Contact extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Page>
        <Masthead
          title="Contact Us"
          desc="Have a question, request a demo, looking for support - we're here to help you!"
        />
        <SayHello />
        <Box>
          <div className="contactInfo">
            <Row>
              {/* <Col lg={8} md={24} sm={24} xs={24} style={{ marginBottom: "20px" }}>
            <Info />
          </Col> */}
              <Col lg={4} md={24} sm={24} xs={24}></Col>
              <Col lg={16} md={24} sm={24} xs={24}>
                <ContactInfo />
              </Col>
              <Col lg={4} md={24} sm={24} xs={24}></Col>
            </Row>
          </div>
        </Box>
      </Page>
    );
  }
}

export default Contact;

import React from "react";
import "./style.css";
import peopleRow from "../../../Images/people-row.png";
import agylzRow from "../../../Images/agylz-row.png";
import appRpw from "../../../Images/application-row.png";
import processRow from "../../../Images/process-row.png";
import automationRow from "../../../Images/automation-row.png";
import BrandedButton from "../../brandedButton";
import { Link } from "react-router-dom";
class Inspire extends React.Component {
  render() {
    return (
      <div className="headd">
        <div className="mobile">
          <div className="center">
            {/* <Link to="/contact">
              <button className="btnMobile customBtnMobile">Get Started</button>
            </Link>
            <br />
            <button className="btnMobileApp marginTop10">
              Get The IOS App
            </button>
            <br /> */}
            <img className="centerImg" src={peopleRow} />
            <h1 className="titles">People</h1>
            <div className="">
              <div className="row">
                <div className="col-sm-4 col-4">
                  <img className="centerImg" src={processRow} />
                  <h1 className="titles">Process</h1>
                </div>
                <div className="col-sm-4 col-4">
                  <img className="centerImg" src={agylzRow} />
                  <h1 className="titles">Agylz</h1>
                </div>
                <div className="col-sm-4 col-4">
                  <img className="centerImg" src={appRpw} />
                  <h1 className="titles">Application</h1>
                </div>
              </div>
              <img className="centerImg" src={automationRow} />
              <h1 className="titles">Automation</h1>
            </div>
          </div>
        </div>
        <div className="web">
          <div className="container">
            <div className="row">
              <div className="col">
                <img className="centerImg" src={agylzRow} />
                <h1 className="titles">
                  Agylz <span className="symbol">=</span>
                </h1>
              </div>
              <div className="col">
                <img className="centerImg" src={peopleRow} />
                <h1 className="titles">
                  People <span className="symbol">+</span>
                </h1>
              </div>
              <div className="col">
                <img className="centerImg" src={processRow} />
                <h1 className="titles">
                  Process <span className="symbol">+</span>
                </h1>
              </div>
              <div className="col">
                <img className="centerImg" src={appRpw} />
                <h1 className="titles">
                  Application <span className="symbol">+</span>
                </h1>
              </div>
              <div className="col">
                <img className="centerImg" src={automationRow} />
                <h1 className="titles">Automation</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Inspire;

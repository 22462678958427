import { Col, Row } from "antd";
import SayHello from "../../components/Contact/say-hello";
import Form from "../../components/forSchool/program-apply/form";
import Page from "../../components/layout";
import Masthead from "../../components/Masthead";
import { Box } from "../../styles/theme";
import "../contactpage/contact.styles.scss";
import "../../components/Contact/contact-info/Info/info.styles.scss";

const ProgramForm = (props) => {
  return (
    <Page>
      <Masthead
        title={"Apply for our " +  new Date().getFullYear() + " free program"}
        desc="Our program simply designed to get you ready for the workforce and help you with how to build tech products from scratch."
      />
      <SayHello />
      <Box>
        <div className="contactInfo">
          <Row>
            <Col lg={4} md={24} sm={24} xs={24}></Col>
            <Col lg={16} md={24} sm={24} xs={24}>
              <Form />
            </Col>
            <Col lg={4} md={24} sm={24} xs={24}></Col>
          </Row>
        </div>
      </Box>
    </Page>
  );
};
export default ProgramForm;

import React, { Component } from "react";
import mail from "../../../../Images/ic_email.png";
import call from "../../../../Images/ic_call.png";
import location from "../../../../Images/ic_location.png";
import facebook from "../../../../Images/img_facebook.png";
import instagram from "../../../../Images/img_instagram.png";
import twitter from "../../../../Images/img_twitter.png";
import "./info.styles.scss";

export default class Info extends Component {
  render() {
    return (
      <div className="discoverWrapper">
        {/* <div className="data">
          <img src={mail} className="imgIcon" alt="/"/>
          <a>admin@agylz.com</a>
        </div> */}
        {/* <div className="data">
          <img src={call} className="imgIcon" alt="/"/>
          <div className="dataDetails">
            <p>
              <a>+1(123) 123 1234</a>
            </p>
            <p>
              <a>+1(123) 123 1234</a>
            </p>
          </div>
        </div> */}
        {/* <div className="data">
          <img src={location} className="imgIcon" alt="/"/>
          <div className="dataDetails">
            <p>Address Line here</p>
            <p>City Name here,</p>
            <p>State Name here,</p>
            <p>ZIP here</p>
            <p>United State</p>
          </div>
        </div> */}
        <div className="social">
          <p> Follow us on</p>
          <div style={{ paddingBottom: "10px" }}>
            <img src={facebook} alt="/" />
            <img src={instagram} alt="/" />
            <img src={twitter} alt="/" />
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";
import "./zoom.styles.scss";
import Page from "../../components/layout";
class Privacy extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Page>
        <div className="container">
          <h1 className="h1Class">Agylz app for Zoom (User guide)</h1>

          <p>
            The Agylz app for Zoom enables teams to build an all-in-one workshop
            virtual space and effectively collaborate during meetings and
            workshops. The app provides Zoom meeting capabilities.{" "}
          </p>

          <p>
            Agylz workshop step is simply a great way to collaborate with your
            team in an agile way and the integration with Zoom will enable the
            audio and video capabilities for remote participants.
          </p>

          <p>
            We are using Zoom authentication to allow you to create a Zoom
            meeting using your Zoom account when you enable the Virtual meeting
            option while creating a concept and attach the Zoom meeting link
            with the concept.
          </p>

          <p>
            The steps to create a Zoom meeting for your Concept virtual meeting
            are as following:
          </p>

          <h2 className="h2Class">1- Click New Concept Tab</h2>

          <h2 className="h2Class">
            2- Choose Discover Concept And Enter The Concept Name
          </h2>

          <h2 className="h2Class">
            3- In The Concept Creation Third Step Enable Concept Virtual Meeting
            By Checking Zoom meeting link box
          </h2>

          <h2 className="h2Class">
            4- Once You Finish The Creation Steps Then You Will Be Redirected To
            Zoom To Authenticate and Link Your Zoom Account.
          </h2>

          <h2 className="h2Class">
            5- Once The Authentication Is Verified A Zoom Meeting Will Be
            Created And A Link Will Be Added Under Your New Concept.
          </h2>

          <h2 className="h2Class">
            6- Click On The Concept You Created And Then Select Summary From The
            Drop Down List.
          </h2>

          <h2 className="h2Class">
            7- Click On The Link And You Will Be Redirected To Your Zoom
            Meeting.
          </h2>

          <br />
          <p>
            <strong>Note: </strong> We don't save or use your authentication
            anywhere else in Agylz
          </p>

          <h4 className="h1Class">Uninstallation of Agylz from Zoom</h4>

          <ul>
            <li>
              1. Login to your Zoom account and navigate to the Zoom App
              Marketplace.
            </li>
            <li>
              2. Click Manage &gt; Installed Apps or search for the Agylz app.
            </li>
            <li>3. Click the Agylz app.</li>
            <li>4. Click Uninstall.</li>
          </ul>
        </div>
      </Page>
    );
  }
}
export default Privacy;

import React from "react";
import "./product.styles.scss";
import Page from "../../components/layout";
import ProductHeader from "../../components/Product/product-header/index";
import ProductInspire from "../../components/Product/product-inspire/index";
import ProductLifeCycle from "../../components/Product/product-lifecycle";
import ProductDetails from "../../components/Product/product-details";
import ProductGetReady from "../../components/Product/product-get-ready";
class Product extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Page>
        <ProductHeader
          title="The fastest way to drive the innovation culture and improve product delivery"
          desc="Collaborate with your team, solve business problems and manage all your product innovation tasks in one single place."
        />
        <ProductInspire />
        <ProductLifeCycle />
        <ProductDetails />
        <ProductGetReady />
      </Page>
    );
  }
}
export default Product;

import React from "react";
import "./style.css";
import { Header, Box } from "../../../../styles/theme";

class FindAway extends React.Component {
  render() {
    return (
      <div className="findAway">
        {/* <Box>
          <Header>Let's find away together</Header>
          <p>
            Be the first to know when AGYLZ launches. Early access users will
            receive an extended free trial and early bird pricing options.
          </p>
        </Box> */}
      </div>
    );
  }
}

export default FindAway;

import React from "react";
import "./style.css";
import Button from "../../brandedButton";
import { Box } from "../../../styles/theme";
import notes from "../../../Images/ic_notes.png";
import { Modal, Form, Input, Icon } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
class Digital extends React.Component {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
      error: "",
      isError: false,
    });
  };

  sendEmail() {
    this.setState({
      visible: false,
    });
    let subject = "Download the process file ";
    let txt = `First Name : ${this.state.first_name} \n Last Name : ${this.state.last_name} \n Email : ${this.state.email} \n Mobile Number : ${this.state.phone}`;
    const data = {
      subject: subject,
      text: txt,
      to: "agylzllc@gmail.com",
      name: `${this.state.first_name} ${this.state.last_name}`,
      from: this.state.email,
    };
    axios
      .post("https://service.agylz.com/customEmail/contact.php", data)
      .then((response) => {
        window.open("http://service.agylz.net/Website%20deck.pdf");
        this.setState({
          isError: false,
          error: "",
        });
      });
  }

  handleOk = (e) => {
    if (this.state.email !== "") {
      if (this.state.first_name !== "") {
        if (this.state.last_name !== "") {
          if (this.state.phone !== "") {
            this.sendEmail();
          } else {
            this.setState({
              isError: true,
              error: "Please Enter Your Phone",
            });
          }
        } else {
          this.setState({
            isError: true,
            error: "Please Enter Your Last Name",
          });
        }
      } else {
        this.setState({ isError: true, error: "Please Enter Your First Name" });
      }
    } else {
      this.setState({ isError: true, error: "Please Enter A Vaild Email" });
    }
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <div className="digialsection">
        <Box>
          <h2 className="titleInn">Digital Consultant</h2>
          <h3 className="text marginTop50">
            We automated the product management, design thinking and agile
            methodology processes, put them at the backend of our application to
            simplify your journey
          </h3>
          <p className="text isHidden" style={{ marginTop: 20 }}>
            Learn more about the combined processes steps, benefits and value
          </p>
          <Modal
            title="Please Fill Your Info"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            okText="Download"
          >
            {this.state.isError && (
              <div className="error">{this.state.error}</div>
            )}
            <Form>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    this.setState({ email: e.target.value, isError: false });
                  }}
                  placeholder="Email"
                  required
                  style={{ marginTop: 10 }}
                ></Input>
                <Input
                  onChange={(e) => {
                    this.setState({
                      first_name: e.target.value,
                      isError: false,
                    });
                  }}
                  required
                  placeholder="First Name"
                  style={{ marginTop: 10 }}
                ></Input>
                <Input
                  onChange={(e) => {
                    this.setState({
                      last_name: e.target.value,
                      isError: false,
                    });
                  }}
                  required
                  placeholder="Last Name"
                  style={{ marginTop: 10 }}
                ></Input>
                <Input
                  onChange={(e) => {
                    this.setState({ phone: e.target.value, isError: false });
                  }}
                  required
                  placeholder="Mobile Number"
                  style={{ marginTop: 10 }}
                ></Input>
              </Form.Item>
            </Form>
          </Modal>
          <div className="buttonDigital">
            <button className="btnMobile">
              <Link style={{ color: "white" }} to="/contact">
                Learn about our process
              </Link>
            </button>
          </div>
          <img src={notes} className="noteImage" />
        </Box>
      </div>
    );
  }
}

export default Digital;

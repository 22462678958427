import React from "react";
import "./homepage.styles.scss";
import Page from "../../components/layout";
import Innovation from "../../components/Home/home-start/index";
import Inspire from "../../components/Home/home-inspire/index";
import Digital from "../../components/Home/home-digital/index";
import Video from "../../components/Home/home-video/index";
import Mobileapp from "../../components/Home/home-mobileapp/index";
import SolveProblems from "../../components/Home/home-solve-prob/index";
import HomeCarousel from "../../components/Home/home-slider/index";

class Home extends React.Component {
  state = {
    scroll: 0,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener("scroll", () => {
      // const isTop = window.scrollY < 100;
      // if (isTop !== this.state.isTop) {
      //     this.setState({ isTop })
      // }
      this.setState({
        scroll: window.scrollY,
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    let scrollTop = event.srcElement.body.scrollTop,
      itemTranslate = Math.min(0, scrollTop / 3 - 60);

    this.setState({
      scroll: itemTranslate,
    });
  }
  render() {
    return (
      <Page className="home">
        <Innovation />
        <Inspire />
        <div className="styleslider" style={{ padding: 30, marginTop: 40 }}>
          <HomeCarousel />
        </div>

        <Digital />
        <Video />
        <Mobileapp />
        <SolveProblems />
      </Page>
    );
  }
}

export default Home;
